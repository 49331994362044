import React, { useState, useEffect } from "react";
import Dropdown from 'react-dropdown';
import useFetch from "utils/hooks/useFetch";

import { EDIT_USER, GET_ALL_ROLE, GET_USER_BY_ID } from "utils/constants/api";
import ConfirmationModal from '../components/modal/ConfirmationModal';
import SuccessModal from '../components/modal/SuccessModal';
import FailModal from '../components/modal/FailModal';
import { SUCCESS_RESPONSE } from '../utils/Constant'
import avatar from "assets/images/avatar.svg"
import LoadingModal from 'components/modal/LoadingModal';
import image_placeholder from "assets/images/no-image.png";

import 'react-dropdown/style.css';

function EditEmployee() {
    const { data: response, fetch: editEmployee } = useFetch(EDIT_USER)
    const { data: data, fetch: getRole } = useFetch(GET_ALL_ROLE)
    const { data: employeeData, fetch: getEmployeeData } = useFetch(GET_USER_BY_ID)
    const [roleOptions, setRoleOptions] = useState([]);
    const [name, setName] = useState();
    const [username, setUsername] = useState();
    const [role, setRole] = useState();
    const [password, setPassword] = useState();
    const [confirmationModal, setConfirmationModal] = useState({
        isOpen: false,
        data: {}
    })
    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        data: {}
    })
    const [failModal, setFailModal] = useState({
        isOpen: false,
        data: {}
    })
    const [loadingModal, setLoadingModal] = useState({
        isOpen: false,
        data: {}
    })
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(image_placeholder);
    function handleChange(e) {
        setFile((e.target.files[0]));
        if (e.target.files[0] != null) {
            setFileUrl(URL.createObjectURL(e.target.files[0]));
        }
    }
    function numberOnly(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }
    function saveClick(e, name, username, role, password) {
        e.preventDefault()
        setConfirmationModal({
            isOpen: true,
            data: {
                onSuccess: async () => {
                    setLoadingModal({ isOpen: true })
                    let data = new FormData();
                    data.append('file', file);
                    data.append('cd', localStorage.getItem("EmployeeId"));
                    data.append('name', name);
                    data.append('username', username);
                    data.append('role_cd', role.value);
                    data.append('updated_by', "admin");
                    data.append('password', password);

                    await editEmployee({
                        data: data,
                        headers: {
                            'Content-type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        if (response.status == SUCCESS_RESPONSE) {
                            setLoadingModal({ isOpen: false })
                            setSuccessModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        } else {
                            setLoadingModal({ isOpen: false })
                            setFailModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        const GetRoles = async () => {
            let _response = []
            await getRole({
                data: {}
            }).then((response) => {
                if (response.status === SUCCESS_RESPONSE) {
                    _response = response.data.map(role => ({ label: role.nm, value: role.cd }))
                    setRoleOptions(_response)
                } else {
                    setLoadingModal({ isOpen: false })
                    setFailModal({
                        isOpen: true,
                        data: {
                            onSuccess: async () => {
                                window.location.reload();
                            }
                        }
                    })
                }
            })
            return _response
        };

        const GetEmployeeData = async (roleOptions) => {
            await getEmployeeData({
                data: {
                    user_cd: localStorage.getItem("EmployeeId")
                }
            }).then((response) => {
                if (response.status === SUCCESS_RESPONSE) {
                    var data = response.data
                    setName(data.name)
                    setUsername(data.username)
                    if (data.img === "" || data.img == null) {
                        setFileUrl(image_placeholder)
                    } else {
                        setFileUrl(data.img)
                    }
                    setRole(
                        {
                            label: roleOptions.find(({ value }) => value === data.role_cd)?.label,
                            value: data.role_cd
                        }
                    )
                    setLoadingModal({ isOpen: false })
                } else {
                    setLoadingModal({ isOpen: false })
                    setFailModal({
                        isOpen: true,
                        data: {
                            onSuccess: async () => {
                                window.location.reload();
                            }
                        }
                    })
                }
            })
            return true
        }
        setLoadingModal({ isOpen: true })
        GetRoles().then((roleOptions) => {
            GetEmployeeData(roleOptions);
        })
    }, [])

    return (
        <div className="w-full mx-auto text-sm sm:text-base">
            <form className="flex flex-col sm:flex-row p-4 space-y-4 sm:space-y-0" onSubmit={(e) => { saveClick(e, name, username, role, password) }}>
                <div className="w-full sm:w-8/12 md:px-6 flex flex-col items-center space-y-2">
                    <div className="border border-app-light-grey bg-white rounded-md max-w-xs aspect-square sm:max-w-none w-full">
                        <img
                            className="aspect-square object-cover h-full mx-auto"
                            src={fileUrl}
                            alt="employee"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = image_placeholder
                            }}
                        />
                    </div>
                    <input className="hidden" id="selectedFile" type="file"
                        accept=".jpg, .png, .jpeg, .gif|image/*"
                        onChange={handleChange} />
                    <div
                        onClick={() => { document.getElementById('selectedFile').click() }}
                        className="px-3 py-2 bg-app-matte-black text-white rounded-md text-center cursor-pointer w-full"
                    >
                        Change Image
                    </div>
                </div>
                <div className="flex flex-col sm:px-6 w-full">
                    <input value={name}
                        onChange={(event) => { setName(event.target.value) }}
                        className="px-4 py-2 rounded-md w-full border border-app-light-grey"
                        placeholder="Name" required></input>
                    <input value={username}
                        onChange={(event) => { setUsername(event.target.value) }}
                        className="mt-5 px-4 py-2 rounded-md w-full border border-app-light-grey"
                        placeholder="Username" required></input>
                    <Dropdown className="mt-5" required options={roleOptions} value={role} onChange={(value) => { setRole(value) }} placeholder="Select role" />
                    <input value={password} required onChange={(event) => { setPassword(event.target.value) }}
                        className="mt-5 px-4 py-2 rounded-md w-full border border-app-light-grey"
                        onKeyPress={(event) => { numberOnly(event) }}
                        type="password"
                        maxLength={4}

                        placeholder="New Pin">
                    </input>
                    <button type="submit"
                        className="ml-auto mt-5 px-3 py-2 bg-app-matte-black text-white w-24 rounded-md">Save</button>
                </div>
            </form>

            {confirmationModal.isOpen && (
                <ConfirmationModal
                    onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
                    data={confirmationModal?.data}
                />
            )}
            {successModal.isOpen && (
                <SuccessModal
                    onClose={() => setSuccessModal({ isOpen: false, data: {} })}
                    data={successModal?.data}
                />
            )}
            {failModal.isOpen && (
                <FailModal
                    onClose={() => setFailModal({ isOpen: false, data: {} })}
                    data={failModal?.data}
                />
            )}
            {loadingModal.isOpen && (
                <LoadingModal onClose={() => setLoadingModal({ isOpen: false })}
                    data={loadingModal?.data}
                />
            )}
        </div>
    )
}

export default EditEmployee