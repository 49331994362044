import React, { useState } from 'react'

import logo from "assets/images/logo.png"
import UserIcon from 'assets/icon/UserIcon'
import MenuIconBlack from 'assets/icon/MenuBlackIcon'
import FoodIcon from "assets/icon/FoodIcon"
import CategoryIcon from "assets/icon/CategoryIcon"

function SideBarOpen() {
   return (
      <div className={`fixed top-0 bg-white shadow-md transition-all z-30 w-60 md:w-80`}>
         <div className={`h-screen  bg-black flex flex-col items-center gap-6 md:text-xs lg:text-base py-4`}>
            <div className='flex w-full items-center px-4'>
               <a className='flex w-full px-4 gap-4' href='/'>
                  <img src={logo} alt="logo" className="h-10 w-10 object-contain" />
                  <h1 className='text-white my-auto font-bold italic'>LABEWA</h1>
               </a>
            </div>
            <div className='items-start flex flex-col w-full gap-2 px-4'>
               <a className={`text-white w-full py-3 px-4 cursor-pointer hover:bg-slate-900 transition-all rounded-md flex ${window.location.pathname === "/" ? "bg-slate-900" : ""}`} href="/" >
                  <FoodIcon />
                  <p className='px-4'>Menu</p>
               </a>
               <a className={`text-white w-full py-3 px-4 cursor-pointer hover:bg-slate-900 transition-all rounded-md flex ${window.location.pathname === "/category" ? "bg-slate-900" : ""}`} href="/category" >
                  <CategoryIcon />
                  <p className='px-4'>Category</p>
               </a>
               <a className={`text-white w-full py-3 px-4 cursor-pointer hover:bg-slate-900 transition-all rounded-md flex ${window.location.pathname === "/employee" ? "bg-slate-900" : ""}`} href="/employee">
                  <UserIcon />
                  <p className='px-4'>Employee</p>
               </a>
            </div>
         </div>
      </div>
   )
}

function SideBarClose({ setMenuOpen }) {
   return (
      <div className="w-full h-screen inset-0 fixed z-40" onClick={() => setMenuOpen(false)}>
         <div id="slide" className={`fixed top-0 bg-white shadow-md z-50 sm:w-3/12 w-7/12`}>
            <div className={`h-screen bg-black flex flex-col items-center gap-4 md:text-xs lg:text-base py-4`}>
               <div className='flex w-full items-center px-4'>
                  <a className='flex w-full gap-4' href='/'>
                     <img className='h-8 w-8 my-2 object-contain' src={logo} alt="logo" />
                     <h1 className='text-white my-auto font-bold italic'>LABEWA</h1>
                  </a>
               </div>
               <div className='items-start flex flex-col w-full gap-2 px-2'>
                  <a className={`text-white w-full py-3 px-2 sm:px-4 cursor-pointer hover:bg-slate-900 active:bg-slate-900 transition-all rounded-md flex ${window.location.pathname === "/" ? "bg-slate-900" : ""}`} href="/" >
                     <FoodIcon />
                     <p className='px-4 text-base font-light'>Menu</p>
                  </a>
                  <a className={`text-white w-full py-3 px-2 sm:px-4 cursor-pointer hover:bg-slate-900 active:bg-slate-900 transition-all rounded-md flex ${window.location.pathname === "/category" ? "bg-slate-900" : ""}`} href="/category" >
                     <CategoryIcon />
                     <p className='px-4'>Category</p>
                  </a>
                  <a className={`text-white w-full py-3 px-2 sm:px-4 cursor-pointer hover:bg-slate-900 active:bg-slate-900 transition-all rounded-md flex ${window.location.pathname === "/employee" ? "bg-slate-900" : ""}`} href="/employee">
                     <UserIcon />
                     <p className='px-4 font-light'>Employee</p>
                  </a>
               </div>
            </div>
         </div>
         <div className="fixed top-0 bottom-0 left-0 right-0 bg-app-matte-black opacity-60 z-30" />
      </div>
   )
}

function Hamburger({ setMenuOpen }) {
   return (
      <div className='fixed transition-all top-0 px-4 py-3 z-30 mr-auto' onClick={() => setMenuOpen(true)}>
         <MenuIconBlack />
      </div>
   )
}

function Sidebar({ isOpen }) {
   const [menuOpen, setMenuOpen] = useState(false);

   if (isOpen) {
      return <SideBarOpen />
   } else {
      if (menuOpen) {
         return <SideBarClose
            setMenuOpen={setMenuOpen}
         />
      } else {
         return <Hamburger
            setMenuOpen={setMenuOpen}
         />
      }
   }
}

export default Sidebar