import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import useFetch from "utils/hooks/useFetch";
import { CHECK_USER_CREDENTIALS } from "utils/constants/api";
import { useAuth } from "utils/context/AuthContext";
import { checkRole } from "utils/helpers";
import { ROLE_ADMIN, ROLE_OWNER } from "utils/constants";

import logo from "assets/images/logo.png"

function Login() {
    const { user } = useAuth()
    const { error: errorLogin, isSubmitting: submittingLogin, fetch: login } = useFetch(CHECK_USER_CREDENTIALS)

    const userToken = Cookies.get("ut")
    const allowed = user && (checkRole(user?.role_cd, ROLE_ADMIN) || checkRole(user?.role_cd, ROLE_OWNER))

    const [formData, setFormData] = useState({
        username: "",
        password: "",
    })

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        const res = await login({
            data: {
                username: formData?.username,
                password: formData?.password,
            }
        })

        if (res.error) {
            setTimeout(() => {
                window.location.reload();
            }, 500)
        } else {
            setTimeout(() => {
                window.location.assign("/")
            }, 500)
        }
    }

    useEffect(() => {
        if (userToken && allowed) {
            window.location.assign("/")
        }
    }, [allowed, userToken])

    const disableLogin = !formData?.username || !formData?.password || submittingLogin

    return (
        <div className="w-screen h-screen flex items-center justify-center p-8">
            <div className="flex flex-col gap-2 w-full max-w-xl">
                <div className="flex justify-center mb-4 sm:mb-8">
                    <img src={logo} alt="logo" className="w-24 sm:w-32 h-24 sm:h-32" />
                </div>
                <input
                    className="border border-app-light-grey rounded-lg px-4 py-2"
                    placeholder="Username"
                    value={formData.username}
                    onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
                    onKeyDown={handleKeyDown}
                />
                <input className="border border-app-light-grey rounded-lg px-4 py-2"
                    placeholder="Password"
                    type="password"
                    value={formData.password}
                    onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                    onKeyDown={handleKeyDown}
                />

                <button
                    className={`mt-2  text-white py-2 ${errorLogin ? 'border-red-500' : ''} ${disableLogin ? "bg-app-light-grey" : "bg-app-matte-black"} border rounded-lg`}
                    disabled={disableLogin}
                    onClick={handleSubmit}
                >
                    Sign In
                </button>

                {errorLogin && (
                    <div>
                        <p className="text-red-500 text-sm">Something went wrong</p>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Login