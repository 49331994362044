import React from 'react'

function CloseSquaredIcon({
   className = "h-6 w-6 fill-white"
}) {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M16.3959 7.75703C16.4889 7.84991 16.5627 7.96019 16.613 8.08159C16.6633 8.20299 16.6892 8.33312 16.6892 8.46453C16.6892 8.59595 16.6633 8.72608 16.613 8.84747C16.5627 8.96887 16.4889 9.07916 16.3959 9.17203L13.4139 12.153L16.0899 14.828C16.2776 15.0157 16.383 15.2702 16.383 15.5355C16.383 15.8009 16.2776 16.0554 16.0899 16.243C15.9023 16.4307 15.6478 16.5361 15.3824 16.5361C15.1171 16.5361 14.8626 16.4307 14.6749 16.243L11.9999 13.567L9.32494 16.243C9.1373 16.4307 8.88281 16.5361 8.61745 16.5361C8.35208 16.5361 8.09759 16.4307 7.90994 16.243C7.7223 16.0554 7.61689 15.8009 7.61689 15.5355C7.61689 15.2702 7.7223 15.0157 7.90994 14.828L10.5859 12.153L7.60394 9.17203C7.51103 9.07906 7.43735 8.96869 7.38709 8.84724C7.33683 8.72578 7.31099 8.59562 7.31104 8.46418C7.31108 8.33274 7.33702 8.20259 7.38736 8.08117C7.4377 7.95976 7.51147 7.84944 7.60444 7.75653C7.69742 7.66362 7.80779 7.58994 7.92924 7.53968C8.05069 7.48942 8.18086 7.46358 8.3123 7.46362C8.44374 7.46367 8.57388 7.48961 8.6953 7.53995C8.81672 7.59029 8.92704 7.66406 9.01995 7.75703L11.9999 10.74L14.9809 7.75803C15.0738 7.66506 15.1841 7.5913 15.3055 7.54097C15.4269 7.49065 15.557 7.46475 15.6884 7.46475C15.8199 7.46475 15.95 7.49065 16.0714 7.54097C16.1928 7.5913 16.3031 7.66406 16.3959 7.75703Z" />
         <path fillRule="evenodd" clipRule="evenodd" d="M4 1C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V20C1 20.7956 1.31607 21.5587 1.87868 22.1213C2.44129 22.6839 3.20435 23 4 23H20C20.7956 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7956 23 20V4C23 3.20435 22.6839 2.44129 22.1213 1.87868C21.5587 1.31607 20.7956 1 20 1H4ZM20 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3Z" />
      </svg>
   )
}

export default CloseSquaredIcon