import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MainContainer from "./components/layout/MainContainer";
import { routes } from "./utils/constants";
import { AuthProvider } from "utils/context/AuthContext";

let router = createBrowserRouter(routes);

function App() {
  return (
    <AuthProvider>
      <MainContainer>
        <RouterProvider
          router={router}
          fallbackElement={<p>Loading...</p>}
        />
      </MainContainer>
    </AuthProvider>
  );
}

export default App;