import ArrowLeftIcon from '../assets/icon/ArrowLeftIcon'
import React from 'react'
import { useNavigate } from 'react-router-dom';

import AvatarIcon from 'assets/icon/AvatarIcon';

function ChangeAccount() {
   const navigate = useNavigate();

   return (
      <div>
         <section className="p-8 flex flex-col justify-center gap-10">
            <div className="flex space-x-5">
               <button onClick={() => navigate("/login")}>
                  <ArrowLeftIcon />
               </button>
               <p className="font-medium text-2xl">Account List</p>
            </div>
            <div className="flex gap-5">
               <button onClick={() => navigate("/login")} className="flex gap-4 px-5 py-3 items-center rounded-md border border-app-light-grey">
                  <AvatarIcon className="w-14 h-14" />
                  <p className="text-2xl font-medium">Kevin Susanto</p>
               </button>
            </div>
         </section>
      </div>
   )
}

export default ChangeAccount