import React, { useState, useEffect } from 'react';

import LoadingModal from 'components/modal/LoadingModal';
import FailModal from 'components/modal/FailModal';
import SuccessModal from 'components/modal/SuccessModal';
import AddCategoryModal from 'components/modal/AddCategoryModal';
import EditCategoryModal from 'components/modal/EditCategoryModal';
import DeleteCategoryModal from 'components/modal/DeleteCategoryModal';

import PlusIcon from 'assets/icon/PlusIcon';
import food from 'assets/images/food.png'

import useFetch from 'utils/hooks/useFetch';
import { DELETE_CATEGORY, GET_ALL_CATEGORY } from "utils/constants/api";
import { SUCCESS_RESPONSE } from 'utils/Constant'
import { useAuth } from 'utils/context/AuthContext';


function Category() {
   const { user } = useAuth();

   const { data: category, fetch: getAllCategory } = useFetch(GET_ALL_CATEGORY)
   const { fetch: deleteCategory } = useFetch(DELETE_CATEGORY)

   const [search, setSearch] = useState();

   const [addCategoryModal, setAddCategoryModal] = useState({
      isOpen: false,
      data: {}
   })
   const [editCategoryModal, setEditCategoryModal] = useState({
      isOpen: false,
      data: {}
   })
   const [deleteCategoryModal, setDeleteCategoryModal] = useState({
      isOpen: false,
      data: {}
   })
   const [loadingModal, setLoadingModal] = useState({
      isOpen: false,
      data: {}
   })
   const [failModal, setFailModal] = useState({
      isOpen: false,
      data: {}
   })
   const [successModal, setSuccessModal] = useState({
      isOpen: false,
      data: {}
   })

   useEffect(() => {
      getAllCategory({
         data: {
            search: search == null ? "" : search
         }
      }).then((response) => {
         if (response.status === SUCCESS_RESPONSE) {
            setLoadingModal({ isOpen: false })
         } else {
            setLoadingModal({ isOpen: false })
            setFailModal({
               isOpen: true,
               data: {
                  onSuccess: async () => {
                     window.location.reload();
                  }
               }
            })
         }
      })
   }, [getAllCategory, search])

   function handleEdit(cd) {
      setEditCategoryModal({ isOpen: true, data: { cd } })
   }

   function handleDelete(cd) {
      setDeleteCategoryModal({
         isOpen: true,
         data: {
            onSuccess: async () => {
               setLoadingModal({ isOpen: true })
               await deleteCategory({
                  data: {
                     cd: cd,
                     deleted_by: user?.cd
                  }
               }).then((response) => {
                  if (response.status === SUCCESS_RESPONSE) {
                     setLoadingModal({ isOpen: false })
                     setSuccessModal({
                        isOpen: true,
                        data: {
                           onSuccess: async () => {
                              window.location.reload();
                           }
                        }
                     })
                  } else {
                     setLoadingModal({ isOpen: false })
                     setFailModal({
                        isOpen: true,
                        data: {
                           onSuccess: async () => {
                              window.location.reload();
                           }
                        }
                     })
                  }
               })
            }
         }
      })
   }

   return (
      <div className="app-main-container">
         <div className='flex gap-2'>
            <input className='border border-app-light-grey rounded-full py-1 px-5 w-10/12'
               value={search} onChange={(event) => { setSearch(event.target.value) }}
               placeholder='Search'
            />
            <button className="sm:px-4 sm:py-2 p-2 flex rounded-md bg-app-matte-black text-white font-semibold ml-auto"
               onClick={() => setAddCategoryModal({ isOpen: true })}
            >
               <PlusIcon />
               <p className='sm:text-base text-sm my-auto pr-2'>Add</p>
            </button>
         </div>

         <div className='sm:pt-6 pt-0 w-full'>
            <div className='text-sm w-full'>
               <div className='mt-8 w-full'>
                  <div className='grid md:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-4 mt-4'>
                     {category?.data?.map((item, i) => {
                        return (
                           <div>
                              <div key={i} className='flex items-center space-x-1 p-2 rounded-[4px] border'>
                                 <div className='flex flex-col w-full'>
                                    <div className='flex border-slate-200 border rounded-sm'>
                                       <div className='bg-slate-200'>
                                          <img
                                             className="w-24 h-24 aspect-square rounded-l-md object-cover"
                                             src={item.img || food}
                                             alt={item.nm}
                                             onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = food
                                             }}
                                          />
                                       </div>
                                       <div className='flex flex-col flex-1 p-2'>
                                          <p className='h-5/6'>{item.nm}</p>
                                       </div>
                                    </div>
                                    <div className='flex gap-2 px-2 py-2 justify-center'>
                                       <button
                                          className='px-3 py-2 bg-app-matte-black text-white rounded-md w-6/12'
                                          onClick={() => handleEdit(item.cd)}
                                       >
                                          Edit
                                       </button>
                                       <button
                                          className='px-2 py-2 bg-white text-black border border-black rounded-md w-6/12'
                                          onClick={() => handleDelete(item.cd)}
                                       >
                                          Remove
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        )
                     })}
                  </div>
               </div>
            </div>
         </div>

         {addCategoryModal.isOpen && (
            <AddCategoryModal onClose={() => setAddCategoryModal({ isOpen: false })} />
         )}
         {editCategoryModal.isOpen && (
            <EditCategoryModal onClose={() => setEditCategoryModal({ isOpen: false })} data={editCategoryModal.data} />
         )}
         {deleteCategoryModal.isOpen && (
            <DeleteCategoryModal onClose={() => setDeleteCategoryModal({ isOpen: false })}
               data={deleteCategoryModal?.data}
            />
         )}
         {loadingModal.isOpen && (
            <LoadingModal onClose={() => setLoadingModal({ isOpen: false })}
               data={loadingModal?.data}
            />
         )}
         {failModal.isOpen && (
            <FailModal
               onClose={() => setFailModal({ isOpen: false, data: {} })}
               data={failModal?.data}
            />
         )}
         {successModal.isOpen && (
            <SuccessModal
               onClose={() => setSuccessModal({ isOpen: false, data: {} })}
               data={successModal?.data}
            />
         )}
      </div>
   )
}

export default Category