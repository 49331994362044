// import ChangeAccount from "pages/ChangeAccount";
import Login from "pages/Login"
import Menu from "../../pages/Menu"
import Employee from "../../pages/Employee"
import ChangeAccount from "pages/ChangeAccount"
import Category from "pages/Category"


export const routes = [
   {
      path: "/",
      element: <Menu />
   },
   {
      path: "/employee",
      element: <Employee />
   },
   {
      path: "/category",
      element: <Category />
   },
   {
      path: "/login",
      element: <Login />
   },
   {
      path: "/change-account",
      element: <ChangeAccount />
   },
]

export const ROLE_ADMIN = "admin"
export const ROLE_OWNER = "owner"