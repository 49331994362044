import React, { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'

import AvatarIcon from 'assets/icon/AvatarIcon'
import { useAuth } from 'utils/context/AuthContext'

function Navbar() {
   const { user } = useAuth()
   const dropdownRef = useRef(null)
   const buttonRef = useRef(null)
   const [dropdownOpen, setDropdownOpen] = useState(false)

   const handleLogout = () => {
      Cookies.remove("ut")
      window.location.reload();
   }

   useEffect(() => {
      function handleClickOutside(event) {
         if (!dropdownRef?.current?.contains(event.target) && !buttonRef?.current?.contains(event.target)) {
            setDropdownOpen(false)
         }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [dropdownRef, buttonRef]);

   return (
      <div className={`sticky top-0 sm:pl-60 md:pl-80 bg-white shadow-md transition-all z-30 w-full`}>
         <div className='app-nav-container flex justify-between items-center'>
            <h1 className='pl-12 sm:pl-0'>Welcome back, {user?.name}</h1>
            <div className="relative flex items-center justify-center">
               <button className="rounded" ref={buttonRef} onClick={() => setDropdownOpen(prev => !prev)}>
                  <AvatarIcon />
               </button>
               {dropdownOpen && (
                  <div ref={dropdownRef} className="absolute text-black top-0 mt-10 bg-white right-0 px-6 py-4 shadow-xl rounded-lg">
                     <button onClick={handleLogout}>
                        Logout
                     </button>
                  </div>
               )}
            </div>
         </div>
      </div>
   )
}

export default Navbar