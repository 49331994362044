import React, { useEffect, useState } from "react";

import ConfirmationModal from 'components/modal/ConfirmationModal';
import SuccessModal from 'components/modal/SuccessModal';
import FailModal from 'components/modal/FailModal';
import LoadingModal from 'components/modal/LoadingModal';

import image_placeholder from "assets/images/no-image.png";

import useFetch from "utils/hooks/useFetch";
import { GET_CATEGORY_BY_CD, UPDATE_CATEGORY } from "utils/constants/api";
import { SUCCESS_RESPONSE } from 'utils/Constant'
import { useAuth } from "utils/context/AuthContext";

import 'react-dropdown/style.css';

function EditCategory({ data }) {
   const { user } = useAuth();
   const { fetch: updateCategory } = useFetch(UPDATE_CATEGORY);
   const { data: category, fetch: getCategoryByCd } = useFetch(GET_CATEGORY_BY_CD)


   const [file, setFile] = useState("");
   const [name, setName] = useState("")
   const [fileUrl, setFileUrl] = useState("");

   const [confirmationModal, setConfirmationModal] = useState({
      isOpen: false,
      data: {}
   })
   const [successModal, setSuccessModal] = useState({
      isOpen: false,
      data: {}
   })
   const [failModal, setFailModal] = useState({
      isOpen: false,
      data: {}
   })
   const [loadingModal, setLoadingModal] = useState({
      isOpen: false,
      data: {}
   })

   function handleChange(e) {
      setFile((e.target.files[0]));
      if (e.target.files[0] != null) {
         setFileUrl(URL.createObjectURL(e.target.files[0]));
      }
   }

   function handleSubmit(file, name) {
      setConfirmationModal({
         isOpen: true,
         data: {
            onSuccess: async () => {
               setLoadingModal({ isOpen: true })
               let data = new FormData();
               data.append('cd', category?.data?.cd);
               data.append('nm', name);
               data.append('file', file);
               data.append('updated_by', user?.cd);

               await updateCategory({
                  data: data,
                  headers: {
                     'Content-type': 'multipart/form-data'
                  }
               }).then((response) => {
                  if (response.status === SUCCESS_RESPONSE) {
                     setLoadingModal({ isOpen: false })
                     setSuccessModal({
                        isOpen: true,
                        data: {
                           onSuccess: async () => {
                              window.location.reload();
                           }
                        }
                     })
                  } else {
                     setLoadingModal({ isOpen: false })
                     setFailModal({
                        isOpen: true,
                        data: {
                           onSuccess: async () => {
                              window.location.reload();
                           }
                        }
                     })
                  }
               })
            }
         }
      })
   }

   useEffect(() => {
      getCategoryByCd({
         data: {
            cd: data?.cd
         }
      })
   }, [data?.cd, getCategoryByCd])

   useEffect(() => {
      if (category?.data) {
         setName(category?.data?.nm)
         setFileUrl(category?.data?.img)
      }
   }, [category?.data])
   return (
      <div className="w-full mx-auto text-sm sm:text-base">
         <div className="flex flex-col sm:flex-row p-4 space-y-4 sm:space-y-0">
            <div className="w-full sm:w-8/12 md:px-6 flex flex-col items-center space-y-2">
               <div className="border border-app-light-grey bg-white rounded-md max-w-xs aspect-square sm:max-w-none w-full">
                  <img
                     className="aspect-square object-cover h-full mx-auto"
                     src={fileUrl}
                     alt="category"
                     onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = image_placeholder
                     }}
                  />
               </div>
               <input
                  className="hidden"
                  id="selectedFile"
                  type="file"
                  accept=".jpg, .png, .jpeg, .gif|image/*"
                  onChange={handleChange}
               />
               <button
                  onClick={() => document.getElementById('selectedFile').click()}
                  className="px-3 py-2 bg-app-matte-black text-white rounded-md text-center cursor-pointer w-full"
               >
                  Change Image
               </button>
            </div>
            <div className="flex flex-col sm:px-6 w-full">
               <input
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  className="px-4 py-2 rounded-md w-full border border-app-light-grey"
                  placeholder="Name"
                  required
               />
               <button
                  className="ml-auto mt-5 px-3 py-2 bg-app-matte-black text-white w-24 rounded-md"
                  onClick={() => handleSubmit(file, name)}
               >
                  Save
               </button>
            </div>
         </div>

         {confirmationModal.isOpen && (
            <ConfirmationModal
               onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
               data={confirmationModal?.data}
            />
         )}
         {successModal.isOpen && (
            <SuccessModal
               onClose={() => setSuccessModal({ isOpen: false, data: {} })}
               data={successModal?.data}
            />
         )}
         {failModal.isOpen && (
            <FailModal
               onClose={() => setFailModal({ isOpen: false, data: {} })}
               data={failModal?.data}
            />
         )}
         {loadingModal.isOpen && (
            <LoadingModal onClose={() => setLoadingModal({ isOpen: false })}
               data={loadingModal?.data}
            />
         )}
      </div>
   )
}

export default EditCategory