import React, { useEffect } from 'react'
import Cookies from 'js-cookie'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import useWindowDimensions from 'utils/windowDeimension'
import { useAuth } from 'utils/context/AuthContext'
import { checkRole } from 'utils/helpers'
import { ROLE_ADMIN, ROLE_OWNER } from 'utils/constants'

function MainContainer({ children }) {
   const { user } = useAuth()
   const { width } = useWindowDimensions();
   const userToken = Cookies.get("ut")

   const allowed = user && (checkRole(user?.role_cd, ROLE_ADMIN) || checkRole(user?.role_cd, ROLE_OWNER))

   useEffect(() => {
      if ((!userToken || !allowed) && window.location.pathname !== "/login") {
         window.location.assign("/login")
         localStorage.removeItem("un")
      }
   }, [allowed, userToken])


   if ((!userToken || !allowed) && window.location.pathname !== "/login") {
      return <div />
   }
   if (window.location.pathname === "/login" || window.location.pathname === "/change-account") {
      return (
         <div className='pb-24'>
            {children}
         </div>
      )
   }
   return (
      <div className='pb-24'>
         <Navbar
            onMobile={width > 640 ? false : true}
         />
         <div className='sm:pl-60 md:pl-80'>
            {children}
         </div>
         <Sidebar
            isOpen={width > 640 ? true : false}
         />
      </div>
   )
}

export default MainContainer