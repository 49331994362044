import React, { useState, useEffect } from 'react';
import useFetch from 'utils/hooks/useFetch';
import PlusIcon from '../assets/icon/PlusIcon';
import AddMenuModal from '../components/modal/AddMenuModal';
import EditMenuModal from '../components/modal/EditMenuModal';
import DeleteMenuModal from '../components/modal/DeleteMenuModal';
import { GET_ALL_MENU, DELETE_MENU } from "utils/constants/api";
import LoadingModal from 'components/modal/LoadingModal';
import FailModal from '../components/modal/FailModal';
import { SUCCESS_RESPONSE } from '../utils/Constant'
import SuccessModal from '../components/modal/SuccessModal';
import food from '../assets/images/food.png'

function Menu() {
    const { data: menu, fetch: getAllMenu } = useFetch(GET_ALL_MENU)
    const { data: response, fetch: deleteMenuItem } = useFetch(DELETE_MENU)
    const [search, setSearch] = useState();
    const [addMenuModal, setAddMenuModal] = useState({
        isOpen: false,
        data: {}
    })
    const [editMenuModal, setEditMenuModal] = useState({
        isOpen: false,
        data: {}
    })
    const [deleteMenuModal, setDeleteMenuModal] = useState({
        isOpen: false,
        data: {}
    })
    const [loadingModal, setLoadingModal] = useState({
        isOpen: false,
        data: {}
    })
    const [failModal, setFailModal] = useState({
        isOpen: false,
        data: {}
    })
    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        data: {}
    })
    const [menuTab, setMenuTab] = useState(0)

    useEffect(() => {
        getAllMenu({
            data: {
                search: search == null ? "" : search
            }
        }).then((response) => {
            if (response.status == SUCCESS_RESPONSE) {
                setLoadingModal({ isOpen: false })
            } else {
                setLoadingModal({ isOpen: false })
                setFailModal({
                    isOpen: true,
                    data: {
                        onSuccess: async () => {
                            window.location.reload();
                        }
                    }
                })
            }
        })
    }, [search])

    function editClick(cd) {
        localStorage.setItem("menuEditCd", cd)
        setEditMenuModal({ isOpen: true })
    }

    function deleteMenu(cd) {
        setDeleteMenuModal({
            isOpen: true,
            data: {
                onSuccess: async () => {
                    setLoadingModal({ isOpen: true })
                    await deleteMenuItem({
                        data: {
                            cd: cd,
                            updated_by: "admin"
                        }
                    }).then((response) => {
                        if (response.status === SUCCESS_RESPONSE) {
                            setLoadingModal({ isOpen: false })
                            setSuccessModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        } else {
                            setLoadingModal({ isOpen: false })
                            setFailModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    return (
        <div className="app-main-container">
            <div className='flex gap-2'>
                <input className='border border-app-light-grey rounded-full py-1 px-5 w-10/12'
                    value={search} onChange={(event) => { setSearch(event.target.value) }}
                    placeholder='Search' />
                <button className="sm:px-4 sm:py-2 p-2 flex rounded-md bg-app-matte-black text-white font-semibold ml-auto"
                    onClick={() => setAddMenuModal({ isOpen: true })}
                >
                    <PlusIcon />
                    <p className='sm:text-base text-sm my-auto pr-2'>Add</p>
                </button>
            </div>

            <div className='sm:pt-6 pt-0 w-full'>
                <div className='text-sm w-full'>
                    <div className='mt-8 w-full'>
                        <div className="flex gap-2 overflow-x-scroll pb-3 w-full">
                            {menu?.data?.map((category, i) => (
                                <button onClick={() => setMenuTab(i)} key={i} className={`min-w-fit flex items-center space-x-1 p-1 rounded-[4px] border ${menuTab === i ? "border-app-matte-black" : "border-app-light-grey"}`}>
                                    <img
                                        className="h-8 w-8 rounded-sm"
                                        src={food}
                                        alt="food"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = `/images/Food_Placeholder.png`;
                                        }} />
                                    <p className="text-sm">{category.name}</p>
                                </button>
                            ))}
                        </div>

                        <div className='grid md:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-4 mt-4'>
                            {menu?.data?.[menuTab]?.menu?.map((item, i) => {
                                return (
                                    <div>
                                        <div key={i} className='flex items-center space-x-1 p-2 rounded-[4px] border'>
                                            <div className='flex flex-col w-full'>
                                                <div className='flex border-slate-200 border rounded-sm'>
                                                    <div className='bg-slate-200'>
                                                        <img
                                                            className="w-24 h-24 aspect-square rounded-l-md object-cover"
                                                            src={item.img}
                                                            alt={item.nm}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = food;
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='flex flex-col flex-1 p-2'>
                                                        <p className='h-5/6'>{item.nm}</p>
                                                        <p className='w-full text-right'>Rp {new Intl.NumberFormat().format(item.price)}</p>
                                                    </div>
                                                </div>
                                                <div className='flex gap-2 px-2 py-2 justify-center'>
                                                    <button className='px-3 py-2 bg-app-matte-black text-white rounded-md w-6/12'
                                                        onClick={() => editClick(item.cd)}>Edit</button>
                                                    <button className='px-2 py-2 bg-app-matte-black text-white rounded-md w-36' hidden>Ingredients</button>
                                                    <button className='px-2 py-2 bg-white text-black border border-black rounded-md w-6/12'
                                                        onClick={() => deleteMenu(item.cd)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {addMenuModal.isOpen && (
                <AddMenuModal onClose={() => setAddMenuModal({ isOpen: false })} />
            )}
            {editMenuModal.isOpen && (
                <EditMenuModal onClose={() => setEditMenuModal({ isOpen: false })} />
            )}
            {deleteMenuModal.isOpen && (
                <DeleteMenuModal onClose={() => setDeleteMenuModal({ isOpen: false })}
                    data={deleteMenuModal?.data}
                />
            )}
            {loadingModal.isOpen && (
                <LoadingModal onClose={() => setLoadingModal({ isOpen: false })}
                    data={loadingModal?.data}
                />
            )}
            {failModal.isOpen && (
                <FailModal
                    onClose={() => setFailModal({ isOpen: false, data: {} })}
                    data={failModal?.data}
                />
            )}
            {successModal.isOpen && (
                <SuccessModal
                    onClose={() => setSuccessModal({ isOpen: false, data: {} })}
                    data={successModal?.data}
                />
            )}
        </div>
    )
}

export default Menu