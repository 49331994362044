import React, { useState, useEffect } from "react";
import Dropdown from 'react-dropdown';
import useFetch from "utils/hooks/useFetch";
import 'react-dropdown/style.css';
import { ADD_MENU, GET_ALL_CATEGORY } from "utils/constants/api";
import ConfirmationModal from '../components/modal/ConfirmationModal';
import SuccessModal from '../components/modal/SuccessModal';
import FailModal from '../components/modal/FailModal';
import { SUCCESS_RESPONSE } from '../utils/Constant'
import LoadingModal from 'components/modal/LoadingModal';
import image_placeholder from "assets/images/no-image.png";

function AddMenu() {
    const { data: response, fetch: addMenuItem } = useFetch(ADD_MENU)
    const { data: category, fetch: getCategory } = useFetch(GET_ALL_CATEGORY)
    const [options, setOptions] = useState([]);
    const [itemName, setItemName] = useState();
    const [itemPrice, setItemPrice] = useState();
    const [itemCategory, setItemCategory] = useState();
    const [itemType, setItemType] = useState();
    const [itemDescription, setItemDescription] = useState();
    const [confirmationModal, setConfirmationModal] = useState({
        isOpen: false,
        data: {}
    })
    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        data: {}
    })
    const [failModal, setFailModal] = useState({
        isOpen: false,
        data: {}
    })
    const [loadingModal, setLoadingModal] = useState({
        isOpen: false,
        data: {}
    })
    const defaultOption = options[0];
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(image_placeholder);
    function handleChange(e) {
        setFile((e.target.files[0]));
        if (e.target.files[0] != null) {
            setFileUrl(URL.createObjectURL(e.target.files[0]));
        }
    }
    function numberOnly(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }
    function addMenu(e, itemName, itemPrice, itemCategory, itemDescription, itemType) {
        e.preventDefault();
        setConfirmationModal({
            isOpen: true,
            data: {
                onSuccess: async () => {
                    setLoadingModal({ isOpen: true })
                    let data = new FormData();
                    data.append('file', file);
                    data.append('nm', itemName)
                    data.append('price', parseInt(itemPrice))
                    data.append('desc', itemDescription)
                    data.append('category_cd', itemCategory.value)
                    data.append('is_drink', itemType.value)
                    data.append('created_by', "admin")
                    await addMenuItem({
                        data: data,
                        headers: {
                            'Content-type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        if (response.status === SUCCESS_RESPONSE) {
                            setLoadingModal({ isOpen: false })
                            setSuccessModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        } else {
                            setLoadingModal({ isOpen: false })
                            setFailModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })

    }
    useEffect(() => {
        setLoadingModal({ isOpen: true })
        getCategory({
            data: {}
        }).then((response) => {
            if (response.status === SUCCESS_RESPONSE) {
                const _response = response.data.map(category => ({ value: category.cd, label: category.nm }))
                setOptions(_response)
                setLoadingModal({ isOpen: false })
            } else {
                setLoadingModal({ isOpen: false })
                setFailModal({
                    isOpen: true,
                    data: {
                        onSuccess: async () => {
                            window.location.reload();
                        }
                    }
                })
            }
        })
    }, [getCategory])

    return (
        <div className="w-full mx-auto text-sm sm:text-base">
            <form
                className="flex flex-col sm:flex-row p-4 space-y-4 sm:space-y-0"
                onSubmit={(e) => addMenu(e, itemName, itemPrice, itemCategory, itemDescription, itemType)}
            >
                <div className="w-full sm:w-8/12 md:px-6 flex flex-col items-center space-y-2">
                    <div className="border border-app-light-grey bg-white rounded-md max-w-xs aspect-square sm:max-w-none w-full">
                        <img
                            className="aspect-square object-cover h-full mx-auto"
                            src={fileUrl}
                            alt="menu"
                        />
                    </div>
                    <input className="hidden" id="selectedFile" type="file"
                        accept=".jpg, .png, .jpeg, .gif|image/*"
                        onChange={handleChange}
                    />
                    <div
                        onClick={() => document.getElementById('selectedFile').click()}
                        className="px-3 py-2 bg-app-matte-black text-white rounded-md text-center cursor-pointer w-full"
                    >
                        Add Image
                    </div>
                </div>
                <div className="flex flex-col sm:px-6 w-full">
                    <input value={itemName} onChange={(event) => { setItemName(event.target.value) }}
                        className="px-4 py-2 rounded-md w-full border border-app-light-grey"
                        placeholder="Item Name" required></input>
                    <input value={itemPrice}
                        onKeyDown={(event) => { numberOnly(event) }}
                        onChange={(event) => { setItemPrice(event.target.value) }}
                        className="mt-5 px-4 py-2 rounded-md w-full border border-app-light-grey"
                        placeholder="Price" required></input>
                    <input value={itemDescription}
                        onChange={(event) => { setItemDescription(event.target.value) }}
                        className="mt-5 px-4 py-2 rounded-md w-full border border-app-light-grey"
                        placeholder="Item Description" required></input>
                    <Dropdown className="mt-5" options={options}
                        value={itemCategory} onChange={(value) => { setItemCategory(value) }}
                        placeholder="Select category" required
                    />
                    <Dropdown className="mt-5" options={[{ label: "Food", value: "N" }, { label: "Drink", value: "Y" }]}
                        value={itemType} onChange={(value) => { setItemType(value) }}
                        placeholder="Select item type" required
                    />
                    <button type="submit" className="ml-auto mt-5 px-3 py-2 bg-app-matte-black text-white w-24 rounded-md">Save</button>
                </div>
            </form>

            {confirmationModal.isOpen && (
                <ConfirmationModal
                    onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
                    data={confirmationModal?.data}
                />
            )}
            {successModal.isOpen && (
                <SuccessModal
                    onClose={() => setSuccessModal({ isOpen: false, data: {} })}
                    data={successModal?.data}
                />
            )}
            {failModal.isOpen && (
                <FailModal
                    onClose={() => setFailModal({ isOpen: false, data: {} })}
                    data={failModal?.data}
                />
            )}
            {loadingModal.isOpen && (
                <LoadingModal onClose={() => setLoadingModal({ isOpen: false })}
                    data={loadingModal?.data}
                />
            )}
        </div>
    )
}

export default AddMenu