import React, { useState, useEffect } from 'react'
import useFetch from 'utils/hooks/useFetch';
import PlusIcon from '../assets/icon/PlusIcon'
import EmployeeModal from '../components/modal/AddEmployeeModal';
import DeleteEmployeeModal from '../components/modal/DeleteEmployeeModal';
import EditEmployeeModal from '../components/modal/EditEmployeeModal';
import { GET_ALL_USER, DELETE_USER } from "utils/constants/api";
import LoadingModal from 'components/modal/LoadingModal';
import FailModal from '../components/modal/FailModal';
import SuccessModal from '../components/modal/SuccessModal';
import { SUCCESS_RESPONSE } from '../utils/Constant';
import avatar from "assets/images/avatar.svg"

function Employee() {
    const { data: userData, fetch: getAllUser } = useFetch(GET_ALL_USER)
    const { fetch: deleteUser } = useFetch(DELETE_USER)
    const [search, setSearch] = useState();
    const [addEmployeeModal, setAddEmployeeModal] = useState({
        isOpen: false,
        data: {}
    })
    const [deleteEmployeeModal, setDeleteEmployeeModal] = useState({
        isOpen: false,
        data: {}
    })
    const [editEmployeeModal, setEditEmployeeModal] = useState({
        isOpen: false,
        data: {}
    })
    const [loadingModal, setLoadingModal] = useState({
        isOpen: false,
        data: {}
    })
    const [failModal, setFailModal] = useState({
        isOpen: false,
        data: {}
    })
    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        data: {}
    })
    const [menuTab, setMenuTab] = useState(0)

    useEffect(() => {
        getAllUser({
            data: {
                search: search
            }
        }).then((response) => {
            if (response.status == SUCCESS_RESPONSE) {
                setLoadingModal({ isOpen: false })
            } else {
                setLoadingModal({ isOpen: false })
                setFailModal({
                    isOpen: true,
                    data: {
                        onSuccess: async () => {
                            window.location.reload();
                        }
                    }
                })
            }
        })
    }, [getAllUser, search])

    function deleteClick(cd) {
        setDeleteEmployeeModal({
            isOpen: true,
            data: {
                onSuccess: async () => {
                    setLoadingModal({ isOpen: true })
                    await deleteUser({
                        data: {
                            cd: cd
                        }
                    }).then((response) => {
                        if (response.status === SUCCESS_RESPONSE) {
                            setLoadingModal({ isOpen: false })
                            setSuccessModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        } else {
                            setLoadingModal({ isOpen: false })
                            setFailModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    function editClick(cd) {
        localStorage.setItem("EmployeeId", cd)
        setEditEmployeeModal({ isOpen: true })
    }

    return (
        <div className="app-main-container">
            <div className='flex gap-2'>
                <input className='border border-app-light-grey rounded-full py-1 px-5 w-10/12'
                    value={search} onChange={(event) => { setSearch(event.target.value) }}
                    placeholder='Search' />
                <button className="sm:px-4 sm:py-2 p-2 flex rounded-md bg-app-matte-black text-white font-semibold ml-auto"
                    onClick={() => setAddEmployeeModal({ isOpen: true })} >
                    <PlusIcon />
                    <p className='sm:text-base text-sm my-auto pr-2'>Add</p>
                </button>
            </div>

            <div className='sm:pt-6 pt-0 w-full'>
                <div className='text-sm w-full'>
                    <div className='mt-8 w-full'>
                        <div className="flex gap-2 overflow-x-scroll pb-3 w-full">
                            {userData?.data?.map((role, i) => (
                                <button onClick={() => setMenuTab(i)} key={i} className={`min-w-fit flex items-center space-x-1 p-1 rounded-[4px] border ${menuTab === i ? "border-app-matte-black" : "border-app-light-grey"}`}>
                                    <img
                                        className="h-8 w-8 rounded-sm"
                                        src={avatar}
                                        alt="avatar"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = avatar
                                        }}
                                    />
                                    <p className="text-sm">{role.role_nm}</p>
                                </button>
                            ))}
                        </div>

                        <div className='grid md:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-4 mt-4'>
                            {userData?.data?.[menuTab]?.user?.map((item, i) => {
                                return (
                                    <div>
                                        <div key={i} className='flex items-center space-x-1 p-2 rounded-[4px] border'>
                                            <div className='flex flex-col w-full'>
                                                <div className='flex border-slate-200 border-2 rounded-sm'>
                                                    <div className='bg-slate-200'>
                                                        <img
                                                            className="w-24 h-24 aspect-square rounded-l-md object-cover"
                                                            src={item.img}
                                                            alt={item.nm}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = avatar;
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='flex flex-col flex-1 p-2'>
                                                        <p className='font-semibold'>{item.name}</p>
                                                        <p >{item.role}</p>
                                                    </div>
                                                </div>
                                                <div className='flex gap-2 px-2 py-2 justify-center'>
                                                    <button className='px-3 py-2 bg-app-matte-black text-white rounded-md w-6/12'
                                                        onClick={() => editClick(item.cd)} >Edit</button>
                                                    <button className='px-2 py-2 border border-black bg-white text-black rounded-md w-6/12'
                                                        onClick={() => deleteClick(item.cd)}>
                                                        Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {addEmployeeModal.isOpen && (
                <EmployeeModal onClose={() => setAddEmployeeModal({ isOpen: false })}
                    data={addEmployeeModal?.data}
                />
            )}
            {deleteEmployeeModal.isOpen && (
                <DeleteEmployeeModal onClose={() => setDeleteEmployeeModal({ isOpen: false })}
                    data={deleteEmployeeModal?.data}
                />
            )}
            {editEmployeeModal.isOpen && (
                <EditEmployeeModal onClose={() => setEditEmployeeModal({ isOpen: false })}
                    data={editEmployeeModal?.data}
                />
            )}
            {loadingModal.isOpen && (
                <LoadingModal onClose={() => setLoadingModal({ isOpen: false })}
                    data={loadingModal?.data}
                />
            )}
            {failModal.isOpen && (
                <FailModal
                    onClose={() => setFailModal({ isOpen: false, data: {} })}
                    data={failModal?.data}
                />
            )}
            {successModal.isOpen && (
                <SuccessModal
                    onClose={() => setSuccessModal({ isOpen: false, data: {} })}
                    data={successModal?.data}
                />
            )}
        </div>
    )
}

export default Employee