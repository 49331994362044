import React, { useState, useEffect } from "react";
import Dropdown from 'react-dropdown';
import useFetch from "utils/hooks/useFetch";
import 'react-dropdown/style.css';
import { GET_MENU_BY_CD, EDIT_MENU, GET_ALL_CATEGORY } from "utils/constants/api";
import ConfirmationModal from '../components/modal/ConfirmationModal';
import food from '../assets/images/food.png'
import SuccessModal from '../components/modal/SuccessModal';
import FailModal from '../components/modal/FailModal';
import { SUCCESS_RESPONSE } from '../utils/Constant'
import LoadingModal from "components/modal/LoadingModal";
import image_placeholder from "assets/images/no-image.png";

function EditMenu() {
    const { fetch: editMenuItem } = useFetch(EDIT_MENU)
    const { fetch: getCategory } = useFetch(GET_ALL_CATEGORY)
    const { fetch: getMenuItem } = useFetch(GET_MENU_BY_CD)
    const [options, setOptions] = useState([]);

    const [itemName, setItemName] = useState();
    const [itemPrice, setItemPrice] = useState();
    const [itemCategory, setItemCategory] = useState();
    const [itemDescription, setItemDescription] = useState();
    const [itemType, setItemType] = useState();

    const [confirmationModal, setConfirmationModal] = useState({
        isOpen: false,
        data: {}
    })
    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        data: {}
    })
    const [failModal, setFailModal] = useState({
        isOpen: false,
        data: {}
    })
    const [loadingModal, setLoadingModal] = useState({
        isOpen: false,
        data: {}
    })
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(image_placeholder);

    function handleChange(e) {
        setFile((e.target.files[0]));
        if (e.target.files[0] != null) {
            setFileUrl(URL.createObjectURL(e.target.files[0]));
        }
    }
    function numberOnly(event) {
        if (!/[0-9\b]/.test(event.key)) {
            event.preventDefault();
        }
    }
    function editMenu(e, itemName, itemPrice, itemCategory, itemDescription, itemType) {
        e.preventDefault()
        setConfirmationModal({
            isOpen: true,
            data: {
                onSuccess: async () => {
                    setLoadingModal({ isOpen: true })
                    let data = new FormData();
                    data.append('file', file);
                    data.append('cd', localStorage.getItem("menuEditCd"));
                    data.append('nm', itemName);
                    data.append('price', parseInt(itemPrice));
                    data.append('desc', itemDescription);
                    data.append('category_cd', itemCategory.value);
                    data.append('is_drink', itemType.value);
                    data.append('updated_by', "admin");

                    await editMenuItem({
                        data: data,
                        headers: {
                            'Content-type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        if (response.status === SUCCESS_RESPONSE) {
                            setLoadingModal({ isOpen: false })
                            setSuccessModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        } else {
                            setLoadingModal({ isOpen: false })
                            setFailModal({
                                isOpen: true,
                                data: {
                                    onSuccess: async () => {
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        const GetCategory = async () => {
            let _response = [];
            await getCategory({
                data: {}
            }).then((response) => {
                if (response.status === SUCCESS_RESPONSE) {
                    _response = response.data.map(category => ({ value: category.cd, label: category.nm }))
                    setOptions(_response)

                } else {
                    setLoadingModal({ isOpen: false })
                    setFailModal({
                        isOpen: true,
                        data: {
                            onSuccess: async () => {
                                window.location.reload();
                            }
                        }
                    })
                }
            })
            return _response
        };

        const GetMenuItem = async (options) => {
            await getMenuItem({
                data: {
                    cd: localStorage.getItem("menuEditCd")
                }
            }).then((response) => {
                if (response.status === SUCCESS_RESPONSE) {
                    var data = response.data
                    setItemName(data.nm)
                    setItemPrice(data.price)
                    setItemDescription(data.desc)
                    if (data.img == "" || data.img == null) {
                        setFileUrl(food)
                    } else {
                        setFileUrl(data.img)
                    }
                    setItemCategory(
                        {
                            label: options?.find(({ value }) => value === data.category_cd)?.label,
                            value: data.category_cd
                        }
                    )
                    setItemType({
                        label: [{ label: "Food", value: "N" }, { label: "Drink", value: "Y" }]?.find(({ value }) => value === data?.is_drink)?.label,
                        value: data?.is_drink
                    })
                    setLoadingModal({ isOpen: false })
                } else {
                    setLoadingModal({ isOpen: false })
                    setFailModal({
                        isOpen: true,
                        data: {
                            onSuccess: async () => {
                                window.location.reload();
                            }
                        }
                    })
                }
            })
        }
        setLoadingModal({ isOpen: true })
        GetCategory().then((options) => {
            GetMenuItem(options);
        })
    }, [getCategory, getMenuItem])

    return (
        <div className="w-full mx-auto text-sm sm:text-base">
            <form
                className="flex flex-col sm:flex-row p-4 space-y-4 sm:space-y-0"
                onSubmit={(e) => { editMenu(e, itemName, itemPrice, itemCategory, itemDescription, itemType) }}
            >
                <div className="w-full sm:w-8/12 md:px-6 flex flex-col items-center space-y-2">
                    <div className="border border-app-light-grey bg-white rounded-md max-w-xs aspect-square sm:max-w-none w-full">
                        <img
                            className="h-full aspect-square object-cover"
                            src={fileUrl}
                            alt="food"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = image_placeholder
                            }}
                        />
                    </div>
                    <input className="hidden" id="selectedFile" type="file"
                        accept=".jpg, .png, .jpeg, .gif|image/*"
                        onChange={handleChange} />
                    <div
                        onClick={() => { document.getElementById('selectedFile').click() }}
                        className="px-3 py-2 bg-app-matte-black text-white rounded-md text-center cursor-pointer w-full"
                    >
                        Change Image
                    </div>
                </div>
                <div className="flex flex-col sm:px-6 w-full">
                    <input value={itemName} onChange={(event) => { setItemName(event.target.value) }} className="px-4 py-2 rounded-md w-full border border-app-light-grey" placeholder="Item Name" required></input>
                    <input value={itemPrice} onKeyPress={(event) => { numberOnly(event) }} onChange={(event) => { setItemPrice(event.target.value) }} className="mt-5 px-4 py-2 rounded-md w-full border border-app-light-grey" placeholder="Price" required></input>
                    <input value={itemDescription} onChange={(event) => { setItemDescription(event.target.value) }} className="mt-5 px-4 py-2 rounded-md w-full border border-app-light-grey" placeholder="Item Description" required></input>
                    <Dropdown className="mt-5" controlClassName='border rounded-sm' options={options} value={itemCategory} onChange={(value) => { setItemCategory(value) }} placeholder="Select category" />
                    <Dropdown className="mt-5" options={[{ label: "Food", value: "N" }, { label: "Drink", value: "Y" }]}
                        value={itemType} onChange={(value) => { setItemType(value) }}
                        placeholder="Select item type" required
                    />
                    <button type="submit" className="ml-auto mt-5 px-3 py-2 bg-app-matte-black text-white w-24 rounded-md">Save</button>
                </div>
            </form>

            {confirmationModal.isOpen && (
                <ConfirmationModal
                    onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
                    data={confirmationModal?.data}
                />
            )}
            {successModal.isOpen && (
                <SuccessModal
                    onClose={() => setSuccessModal({ isOpen: false, data: {} })}
                    data={successModal?.data}
                />
            )}
            {failModal.isOpen && (
                <FailModal
                    onClose={() => setFailModal({ isOpen: false, data: {} })}
                    data={failModal?.data}
                />
            )}
            {loadingModal.isOpen && (
                <LoadingModal
                    onClose={() => setLoadingModal({ isOpen: false, data: {} })}
                    data={loadingModal?.data}
                />
            )}
        </div>
    )
}

export default EditMenu