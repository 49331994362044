export const API_BASE_URL = process.env.REACT_APP_API_URL
export const CUSTOMER_SITE_URL = process.env.REACT_APP_CUSTOMER_URL



export const ADD_BILL_DETAIL = {
   method: 'post',
   url: "/bulk-add-bill-detail"
}

export const GET_BILL = {
   method: 'post',
   url: "/get-bill-detail"
}
export const CLOSE_BILL = {
   method: 'post',
   url: "/close-bill"
}

export const PAID_BILL = {
   method: 'post',
   url: "/paid-bill"
}

export const GET_TABLE = {
   method: 'post',
   url: "/get-all-table"
}

export const ADD_BILL = {
   method: 'post',
   url: "/add-bill"
}
export const UPDATE_BILL = {
   method: 'post',
   url: "/update-bill"
}
export const GET_ALL_WAITING_LIST = {
   method: 'post',
   url: "/get-all-waiting-list"
}
export const GET_WAITING_LIST_DETAIL = {
   method: 'post',
   url: "/get-waiting-list-dtl"
}
export const DELETE_WAITING_LIST = {
   method: 'post',
   url: "/delete-waiting-list"
}
export const ADD_WAITING_LIST = {
   method: 'post',
   url: "/add-waiting-list"
}
export const GET_ALL_ORDER_KITCHEN = {
   method: 'post',
   url: "/get-bill-all-detail"
}

export const UPDATE_BILL_DETAIL = {
   method: 'post',
   url: "/update-bill-detail"
}

export const PRINT_BILL_DETAIL = {
   method: 'post',
   url: "/get-bill-detail-by-cd-print"
}

export const DELETE_BILL_DETAIL_BULK = {
   method: "post",
   url: "/delete-bill-detail-bulk"
}

export const ADD_BILL_SUMMARY = {
   method: "post",
   url: "/add-bill-summary"
}

export const GET_BILL_SUMMARY = {
   method: "post",
   url: "/get-bill-summary"
}

export const GET_BILL_SUMMARY_DTL = {
   method: "post",
   url: "/get-bill-summary-dtl"
}

// Menu ==========================================================================
export const GET_MENU_BY_CD = {
   method: 'post',
   url: "/menu-by-cd"
}
export const GET_ALL_MENU = {
   method: 'post',
   url: "/menu-all"
}
export const ADD_MENU = {
   method: "post",
   url: "/add-menu"
}
export const EDIT_MENU = {
   method: "post",
   url: "/update-menu"
}
export const DELETE_MENU = {
   method: "post",
   url: "/delete-menu"
}

// User ==========================================================================
export const GET_ALL_USER = {
   method: "post",
   url: "/get-all-user"
}
export const ADD_USER = {
   method: "post",
   url: "/add-user"
}
export const DELETE_USER = {
   method: "post",
   url: "/delete-user"
}
export const EDIT_USER = {
   method: "post",
   url: "/update-user"
}
export const GET_USER_BY_ID = {
   method: "post",
   url: "/get-user"
}
export const UPLOAD_IMAGE = {
   method: "post",
   url: "/add-user-img"
}

// Category ==========================================================================
export const GET_ALL_CATEGORY = {
   method: "post",
   url: "/get-all-category"
}
export const GET_CATEGORY_BY_CD = {
   method: "post",
   url: "/get-category-by-cd"
}
export const ADD_CATEGORY = {
   method: "post",
   url: "/add-category"
}
export const UPDATE_CATEGORY = {
   method: "post",
   url: "/update-category"
}
export const DELETE_CATEGORY = {
   method: "post",
   url: "/delete-category"
}

// Role ==============================================================================
export const GET_ALL_ROLE = {
   method: "post",
   url: "/get-all-role"
}

export const CHECK_USER_CREDENTIALS = {
   method: "post",
   url: "/check-user-credential-admin"
}