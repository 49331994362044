import React from 'react'

function CategoryIcon({
   className = "w-6 h-6 fill-white"
}) {
   return (
      <svg className={className} viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M10.0625 6.37177H15.985C17.1321 6.37175 18.2319 6.82874 19.0412 7.64164C19.8505 8.45454 20.3026 9.55637 20.2975 10.7034V16.6068C20.2975 18.9885 18.3668 20.9193 15.985 20.9193H10.0625C7.68081 20.9193 5.75004 18.9885 5.75004 16.6068V10.7034C5.74494 9.55637 6.19704 8.45454 7.00635 7.64164C7.81565 6.82874 8.91547 6.37175 10.0625 6.37177ZM15.985 18.0251C16.7746 18.0148 17.4122 17.3772 17.4225 16.5876V10.7034C17.4225 9.90952 16.779 9.26593 15.985 9.26593H10.0625C9.26863 9.26593 8.62504 9.90952 8.62504 10.7034V16.5876C8.63537 17.3772 9.27295 18.0148 10.0625 18.0251H15.985Z" />
         <path fillRule="evenodd" clipRule="evenodd" d="M30.0151 6.37177H35.9376C37.0847 6.37175 38.1845 6.82874 38.9938 7.64164C39.8031 8.45454 40.2552 9.55637 40.2501 10.7034V16.6068C40.2501 18.9885 38.3193 20.9193 35.9376 20.9193H30.0151C27.6334 20.9193 25.7026 18.9885 25.7026 16.6068V10.6843C25.7026 8.30254 27.6334 6.37177 30.0151 6.37177ZM35.9376 18.0251C36.7272 18.0148 37.3648 17.3772 37.3751 16.5876V10.7034C37.3751 9.90952 36.7315 9.26593 35.9376 9.26593H30.0151C29.2212 9.26593 28.5776 9.90952 28.5776 10.7034V16.5876C28.5879 17.3772 29.2255 18.0148 30.0151 18.0251H35.9376Z" />
         <path fillRule="evenodd" clipRule="evenodd" d="M10.0625 26.3243H15.985C18.3668 26.3243 20.2975 28.255 20.2975 30.6368V36.5593C20.2975 38.941 18.3668 40.8718 15.985 40.8718H10.0625C8.91547 40.8718 7.81565 40.4148 7.00635 39.6019C6.19704 38.789 5.74494 37.6872 5.75004 36.5401V30.6368C5.75004 28.255 7.68081 26.3243 10.0625 26.3243ZM15.985 37.9776C16.779 37.9776 17.4225 37.334 17.4225 36.5401V30.6368C17.4122 29.8472 16.7746 29.2096 15.985 29.1993H10.0625C9.27295 29.2096 8.63537 29.8472 8.62504 30.6368V36.5401C8.62504 37.334 9.26863 37.9776 10.0625 37.9776H15.985Z" />
         <path fillRule="evenodd" clipRule="evenodd" d="M30.015 26.3243H35.9375C38.3193 26.3243 40.25 28.255 40.25 30.6368V36.5401C40.2551 37.6872 39.803 38.789 38.9937 39.6019C38.1844 40.4148 37.0846 40.8718 35.9375 40.8718H30.015C28.868 40.8718 27.7682 40.4148 26.9588 39.6019C26.1495 38.789 25.6974 37.6872 25.7025 36.5401V30.6368C25.7025 28.255 27.6333 26.3243 30.015 26.3243ZM35.9375 37.9776C36.7314 37.9776 37.375 37.334 37.375 36.5401V30.6368C37.3647 29.8472 36.7271 29.2096 35.9375 29.1993H30.015C29.2254 29.2096 28.5879 29.8472 28.5775 30.6368V36.5401C28.5775 37.334 29.2211 37.9776 30.015 37.9776H35.9375Z" />
      </svg>
   )
}

export default CategoryIcon